//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import global from '../mixins/global';

export default {
  name: 'DevTool',
  mixins: [global],
  data: () => ({
    showDevTool: true,
    path: undefined,
  }),
  computed: {
    ...mapGetters({
      user: 'getUser',
      application: 'getApplication',
      schedule: 'getSchedule',
      subscription: 'getSubscription',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.path = this.$route.path.replace(/\//g, '-').substring(1);
      },
    },
  },
  methods: {
    showState() {
      // Ignore log here, this function can only execute in a local environment
      console.log(this.$store.state);
    },
  },
};
