/** @type {import("@nuxt/types").Plugin} */
export default () => {
  // Add hubspot script to the dom

  const hubspotScript = document.createElement('script');
  hubspotScript.type = 'text/javascript';
  hubspotScript.id = 'hs-script-loader';
  hubspotScript.async = true;
  hubspotScript.defer = true;
  hubspotScript.src = '//js.hs-scripts.com/20033384.js';
  document.head.appendChild(hubspotScript);
};
