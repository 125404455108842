//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// Components
import CheckIcon from '../components/icons/CheckIcon';
import AlertIcon from '../components/icons/AlertIcon';
import ErrorIcon from '../components/icons/ErrorIcon';

export default {
  name: 'Modal',
  components: { CheckIcon, AlertIcon, ErrorIcon },
  props: {
    title: { type: String, required: false, defualt: '' },
    messageType: { type: String, required: false, defualt: '' },
  },
  created() {
    // Capture the error message in full story
    this.$fullStory.log(`Modal Error Message - ${this.title}`);
  },
};
