export default async function (application, axios) {
  try {
    if (application) {
      const cleanApp = {
        payAmount: application.payAmount,
        status: application.status,
        balance: application.balance,
        monthlyIncome: application.monthlyIncome || 0,
        communityName: application.communityName,
        enrollmentDate: application.enrollmentDate,
        frequency: application.frequency,
        communityId: application.communityId,
        lastPayday: application.lastPayday,
        semiMonthlyIncomeOneFirstPayDate: application.semiMonthlyFirstPayDay,
        semiMonthlyIncomeOneSecondPayDate: application.semiMonthlySecondPayDay,
        scheduleStartDateOverride: application.lastPayday,
        applicationType: application.applicationType,
        residentCode: application.residentCode,
        residentId: application.residentId,
        monthlyFee: application.monthlyFee,
        rentAmount: application.rentAmount,
        isOnHold: false, // Always set to false on update request
        assistanceAccepted: application.assistanceAccepted,
        assistanceRequested: application.assistanceRequested,
        assistanceAmount: application.assistanceAmount,
        assistanceAbleToPayAmount: application.assistanceAbleToPayAmount,
        assistanceReason: application.assistanceReason,
        assistanceEarningsType: application.assistanceEarningsType,
        user: {
          address1: application.user.address1,
          city: application.user.city,
          email: application.user.email,
          firstName: application.user.firstName,
          lastName: application.user.lastName,
          phoneNumber: application.user.phoneNumber,
          postalCode: application.user.postalCode,
          state: application.user.state,
        },
      };

      // Updates an existing application
      const response = await axios.patch(`/application/${application.applicationId}`, cleanApp);

      return response.data;
    } else {
      throw new Error('Unable to update application');
    }
  } catch (err) {
    throw new Error(err);
  }
}
