//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SkinnyButton',
  props: {
    buttonText: { type: String, default: 'Continue' },
    primary: { type: Boolean, default: false },
    borderless: { type: Boolean, default: false },
    invalid: { type: Boolean, default: true },
  },
};
