export default async function (application, axios) {
  try {
    if (!application) {
      throw new Error('Unable to locate application.');
    }
    delete application.hasApplication;

    // First request submits the application
    const { data: applicationId } = await axios.post(`/application`, application);

    // Fetch the application here so we can put it in the store
    const { data: submittedApplication } = await axios.get(`/application/${applicationId}`);

    return submittedApplication;
  } catch (err) {
    throw new Error(err);
  }
}
