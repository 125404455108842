export default {
  computed: {
    cutOffDay() {
      if (this.$store.state.community) {
        // Custom cut off for this community.|| Fallback to 5 if the endpoint fails
        return this.$store.state.community.budget_and_save_cutoff_day || 5;
      }
    },
    startOfThisMonth() {
      return this.$dayjs().startOf('month').toDate();
    },
    thisMonth() {
      return this.$dayjs(this.startOfThisMonth).format('MMMM');
    },
    startOfNextMonth() {
      return this.$dayjs().add(1, 'month').startOf('month').toDate();
    },
    nextMonth() {
      return this.$dayjs(this.startOfNextMonth).format('MMMM');
    },
  },
  methods: {
    formatCurrency(amount) {
      const formattedCurrency = parseFloat(amount).toFixed(2);

      return `$${formattedCurrency}`;
    },
    formatDate(date) {
      return this.$dayjs(date).utc().format('MMMM Do');
    },
    countDownToExpirationDate(countDownDate) {
      const now = new Date().getTime();
      // Find the distance between now and the count down date
      const duration = this.$dayjs(countDownDate).endOf('day') - now;

      const timeToDate = this.$dayjs.duration(duration).$d;
      const { days, hours, minutes } = timeToDate;

      // Formatted countdown
      return duration > 0 && ` ${days ? days + ' days' : ''} ${hours ? hours + ' hours' : ''} ${ minutes ? minutes + ' minutes' : '' }`; // prettier-ignore
    },
    scheduleExpirationDate(firstPaymentDay) {
      const dayOfMonth = this.$dayjs().date();
      const thisMonthCutOffDayTime = this.$dayjs().date(this.cutOffDay).$d; // [cutOffDay] in the current month
      const firstPaymentDayTime = this.$dayjs(firstPaymentDay).$d; // The users first payment date
      const nextMonthCutOffDayTime = this.$dayjs().endOf('month').add(this.cutOffDay, 'day').$d; // [cutOffDay] in the next month

      const countDownDate =
        dayOfMonth <= this.cutOffDay
          ? thisMonthCutOffDayTime // If applying on 1-[cutOffDay] of month, schedule expires after the 5th of month
          : nextMonthCutOffDayTime > firstPaymentDayTime
          ? firstPaymentDayTime // If applying [cutOffDay]-end of month, schedule expires when their first payment day passes
          : nextMonthCutOffDayTime; // OR the [cutOffDay] of the next month passes

      return countDownDate;
    },
    getMonth(date) {
      return this.$dayjs.utc(date).format('MMMM');
    },
    getNextMonth(date) {
      return this.$dayjs.utc(date).add(1, 'month').format('MMMM');
    },
    getPaymentMonth(date) {
      // The current payment month cuts off [cutOffDay] days into the next month
      const shouldFormatCurrentMonth =
        parseFloat(this.$dayjs.utc(date).format('DD')) <= this.cutOffDay;

      return shouldFormatCurrentMonth
        ? this.$dayjs.utc(date).format('MMMM')
        : this.$dayjs.utc(date).add(1, 'M').format('MMMM');
    },
  },
};
