//
//
//
//
//
//
//
//
//

export default { name: 'UserIcon' };
