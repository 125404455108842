import actions from './actions';

// state
const state = () => ({
  // Used for app state and navigation
  errorModal: {},
  pageContext: {},
  // Data
  user: {},
  epmsRecord: {},
  schedule: {},
  application: {},
  subscription: {},
  account: {},
  community: {},
  paymentProfile: {},
  inProgressApplication: {},
});

// getters
const getters = {
  getErrorModal: (state) => state.errorModal,
  getPageContext: (state) => state.pageContext,
  getUser: (state) => state.user,
  getEpmsRecord: (state) => state.epmsRecord,
  getSchedule: (state) => state.schedule,
  getApplication: (state) => state.application,
  getSubscription: (state) => state.subscription,
  getAccount: (state) => state.account,
  getCommunity: (state) => state.community,
  getPaymentProfile: (state) => state.paymentProfile,
  getInProgressApplication: (state) => state.inProgressApplication,
};

// mutations
const mutations = {
  storeErrorModal(state, payload) {
    state.errorModal = { ...payload };
  },
  storePageContext(state, payload) {
    state.pageContext = { ...payload };
  },
  storeUser(state, payload) {
    state.user = { ...state.user, ...payload };
  },
  storeEpmsRecord(state, payload) {
    state.epmsRecord = { ...payload };
  },
  storeSchedule(state, payload) {
    state.schedule = { ...state.schedule, ...payload };
  },
  storeApplication(state, payload) {
    state.application = { ...state.application, ...payload };
    // Always copy the latest application to the inProgressApplication
    state.inProgressApplication = { ...state.application };
  },
  storeSubscription(state, payload) {
    state.subscription = { ...state.subscription, ...payload };
  },
  storeAccount(state, payload) {
    state.account = { ...state.account, ...payload };
  },
  storeCommunity(state, payload) {
    state.community = { ...payload };
  },
  storePaymentProfile(state, payload) {
    state.paymentProfile = { ...state.paymentProfile, ...payload };
  },
  storeInProgressApplication(state, payload) {
    state.inProgressApplication = {
      ...state.application,
      ...state.inProgressApplication,
      ...payload,
    };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
