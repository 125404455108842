//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default { name: 'BlueLogo' };
