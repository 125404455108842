import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=7b9eff7a&"
import script from "./NavBar.vue?vue&type=script&lang=js&"
export * from "./NavBar.vue?vue&type=script&lang=js&"
import style0 from "./NavBar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavMenu: require('/Users/juliaschultz/dev/hello-till/web-app/components/nav/NavMenu.vue').default})
