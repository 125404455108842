// This refresh implementation is based on:
// https://github.com/nuxt-community/auth-module/blob/7e3a6a5fdcec2aaf791fc2ecfd0cfc5bfc447d75/lib/core/refreshController.js

// Can handle a bare JWT or a token in the header value format:
// Bearer eyJ...
const parseToken = (token) =>
  JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'));

const tokenIsExpired = (token) => {
  const { exp: expiresAt } = parseToken(token);
  // returns true if the token is expired or will expire within the next 60 seconds
  return (expiresAt - 60) * 1000 < Date.now();
};

const getRefreshConfig = ($auth) => $auth.strategy.options.customTillConfig.refresh;

/** @type {import("@nuxt/types").Plugin} */
export default function ({ $axios, $flags, $log, app }) {
  // This onRequest interceptor will refresh our access token if it is expired
  $axios.onRequest(async (config) => {
    const $auth = app.$auth; // We have to defer this to request-time for `$auth` to be present on `app` ¯\_(ツ)_/¯
    const refreshConfig = getRefreshConfig($auth);

    if (
      config.url === refreshConfig.url ||
      (refreshConfig.ignore && refreshConfig.ignore.includes(config.url))
    ) {
      $log.debug(`skipping refresh for url ${config.url}`);
      return config;
    }

    // Note that `accessToken` is not a bare JWT, but in the header value format:
    // Bearer eyJ...
    const accessToken = $auth.getToken($auth.strategy.name);
    const isExpired = accessToken && tokenIsExpired(accessToken);
    if (!accessToken || isExpired) {
      $log.debug(`refresh auth reason: ${!accessToken ? 'no access token' : 'token is expired'}`);
      await handleRefreshAuth({ $auth, $axios, $log });
      // handleRefreshAuth has updated the token for future requests,
      // but we also need to update the current request's auth header
      // @ts-ignore
      config.headers[$auth.strategy.options.tokenName] = $auth.getToken($auth.strategy.name);
    }

    $flags['use-epms-api'] && (config.headers['use-epms-api'] = 'true');
    $flags['fetch-live-balance'] && (config.headers['fetch-live-balance'] = 'true');
    $flags['send-subscription-to-queue'] && (config.headers['send-subscription-to-queue'] = 'true');

    return config;
  });
}

let _refreshPromise = null;

/**
 * @param {Pick<import("@nuxt/types").Context, "$axios" | "$log"> & Pick<import("@nuxt/types").NuxtAppOptions, "$auth">} params
 */
function performRefresh({ $auth, $axios, $log }) {
  const refreshConfig = getRefreshConfig($auth);
  _refreshPromise = new Promise((resolve) => {
    const refreshToken = $auth.getRefreshToken($auth.strategy.name);
    if (!refreshToken) {
      $log.error('no refresh token');
      $auth.reset().then(() => {
        _refreshPromise = null;
        resolve();
      });
      return;
    }
    $log.debug('refreshing access token');
    $axios(refreshConfig.url, {
      withCredentials: true,
      method: refreshConfig.method,
      headers: { [refreshConfig.name]: refreshToken },
    })
      .then(({ data }) => {
        if (data.accessToken) {
          // @ts-ignore
          const token = $auth.strategy.options.tokenType
            ? // @ts-ignore
              $auth.strategy.options.tokenType + ' ' + data.accessToken
            : data.accessToken;
          $auth.setToken($auth.strategy.name, token);
          // @ts-ignore
          $auth.strategy._setToken(token);
          $log.debug('access token refreshed');
        } else {
          throw new Error('Could not refresh access token');
        }
      })
      .catch((err) => {
        $log.error(err);
        return $auth.reset();
      })
      .then(() => {
        _refreshPromise = null;
        resolve();
      });
  });

  return _refreshPromise;
}

function handleRefreshAuth({ $auth, $axios, $log }) {
  if (_refreshPromise) {
    return _refreshPromise;
  }
  return performRefresh({ $auth, $axios, $log });
}
