//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default { name: 'CheckIcon' };
