//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable camelcase */
import { mapGetters } from 'vuex';
import global from '../mixins/global';

// Components
import NavBar from '../components/nav/NavBar';
import DevTool from '../components/DevTool';
import FullScreenModal from '../components/FullScreenModal';
import SkinnyButton from '../components/buttons/SkinnyButton';
import CloseCircleIcon from '../components/icons/CloseCircleIcon';
import CircledQuestionMarkIcon from '../components/icons/CircledQuestionMarkIcon';

export default {
  components: {
    NavBar,
    DevTool,
    FullScreenModal,
    SkinnyButton,
    CloseCircleIcon,
    CircledQuestionMarkIcon,
  },
  mixins: [global],
  middleware: 'route',
  data: () => ({
    isIframe: false,
    isMobileSafari: false,
    isShowingErrorModal: false,
    showRentRollRefreshModal: false,
    showBalanceModal: false,
    showScheduleModal: false,
    showSchedulePaymentsFailedModal: false,
    showCommunityEligiblityModal: false,
    showDenyMatchModal: false,
    showApplicationEligiblityModal: false,
  }),
  computed: {
    ...mapGetters({
      user: 'getUser',
      application: 'getApplication',
      subscription: 'getSubscription',
      errorModal: 'getErrorModal',
    }),
    logOutClose() {
      // When opened inside of an iFrame, the "Log Out/Close" button should just close the iFrame
      return this.isIframe
        ? { text: 'Close', action: this.closeIframe }
        : { text: 'Log Out', action: this.logOut };
    },
  },
  watch: {
    errorModal({ errorType }) {
      const currentPath = this.$router.currentRoute.fullPath;
      const basePath = currentPath.split('/').filter((path) => path !== '')[0];

      // Log the error type every time we show a modal
      this.$log.error({ userId: this.user.userId }, `Error appeared on ${currentPath},`);
      // Log the error type and subscription record for this user in full story
      this.$fullStory.log(
        { type: errorType, subscription: this.subscription },
        `Error appeared on ${currentPath},`
      );

      // Handles errors in the apply flow
      if (
        basePath === 'apply' &&
        !['/apply/pending', '/apply/confirmation'].includes(currentPath) // Do not show errors on the pending or confirmation screens
      ) {
        this.isShowingErrorModal = true;
        // Shows when the decline reason on the app is 'DOES_NOT_MEET_COMMUNITY_ELIGIBILITY_REQUIREMENTS'
        if (errorType === 'community-eligibility-error') this.showCommunityEligiblityModal = true;
        // Shows when the deny because there are no matches, which includes due to the community not accepting new enrollments
        else if (errorType === 'deny-match') this.showDenyMatchModal = true;
        // Shows when the decline reason on the app is 'ELIGIBILITY_NOT_MET'
        else if (errorType === 'ELIGIBILITY_NOT_MET') this.showApplicationEligiblityModal = true;
        // Will show balance error if an app is auto declined on the set-schedule screen
        else if (errorType === 'application-auto-declined') this.showBalanceModal = true;
        // Will show the refresh error on the /apply/accept-schedule screen
        else if (errorType === 'MISSING_RENT_ROLL_DATA') this.showRentRollRefreshModal = true;
        // Show modal if there is any error type except MISSING_RENT_ROLL_DATA
        else if (errorType) this.showScheduleModal = true;
      }
      // Handles errors for subscribed users
      else if (basePath === 'rent') {
        this.isShowingErrorModal = true;
        // For the near term we'll just show the same error in both of the following cases, since we can't show the user anything without tne payments array
        if (errorType === 'schedule-failed') this.showScheduleModal = true;
        else if (errorType === 'schedule-payments-failed') this.showScheduleModal = true;
      }
    },
  },
  mounted() {
    this.isIframe = window.location !== window.parent.location || window.frameElement;
    this.isMobileSafari = navigator.userAgent.includes('Mobile', 'Safari');

    this.$store.commit('storePageContext', { isIframe: this.isIframe });

    // Zendesk widget settings
    window.zESettings = {
      webWidget: {
        position: { horizontal: 'right', vertical: 'bottom' },
        color: { launcherText: '#ffffff' },
      },
    };
    // Zendesk widget
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.async = true;
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=9cdd784b-c9aa-447e-b0ef-f2b8a5dd3f22'; // prettier-ignore
    document.head.appendChild(script);
  },
  methods: {
    openChat() {
      window.zE.activate();
    },
    closeIframe() {
      window.parent.postMessage({ tillEvent: 'CLOSE_APP', finished: false, enrolled: false }, '*');
    },
  },
};
