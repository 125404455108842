//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

// Components
import NavMenu from './NavMenu';
import BlueLogo from '~/components/icons/BlueLogo';
import MenuIcon from '~/components/icons/MenuIcon';
import UserIcon from '~/components/icons/UserIcon';
import CloseMenuIcon from '~/components/icons/CloseMenuIcon';

export default {
  name: 'NavBar',
  components: { NavMenu, BlueLogo, UserIcon, MenuIcon, CloseMenuIcon },
  data: () => ({
    path: '',
    showMenu: false,
  }),
  computed: {
    ...mapGetters({ user: 'getUser' }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.path = this.$route.path.replace(/\//g, '-').substring(1);
      },
    },
  },
  methods: {
    handleClickMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
