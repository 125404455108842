/* eslint-disable camelcase */
import { mapGetters } from 'vuex';

import submitApplication from '~/api/application/submit-application';
import updateApplication from '~/api/application/update-application';
import approveApplication from '~/api/application/approve-application';
import format from '~/mixins/format';

export default {
  mixins: [format],
  computed: {
    ...mapGetters({ schedule: 'getSchedule' }),
    applicationAtEndOfMonth() {
      const today = this.$dayjs().date();
      return today >= 27 || today <= this.cutOffDay;
    },
    applicationAtStartOfMonthCutOff() {
      const nowLocal = this.$dayjs();
      const nowUTC = nowLocal.utc();
      /**
       * This is the total amount of time that enrollment will be disabled for residents on the 1st of the month.
       * We need to disable enrollment for a period of time on the first to allow time for the epms to provide us
       * with the resident's latest information.
       */
      const cutOffDuration = this.$flags['end-of-month-cut-off-hours'];

      if (nowUTC.date() === 1) {
        // On the first day of the month utc...
        const utcCutOff = nowUTC.startOf('month'); // 7pm eastern time last day of month
        const easternCutOff = utcCutOff.add(cutOffDuration, 'hours'); // 9am eastern time first day of next month

        // Return true if the current time is between the two cut off times
        return nowLocal.isAfter(utcCutOff) && nowLocal.isBefore(easternCutOff);
      } else return false;
    },
    applicationAtStartOfMonthHighBalance() {
      if (this.schedule.rentRoll) {
        const { pastDueRentAmount, upcomingRentAmount } = this.schedule.rentRoll;

        const today = this.$dayjs().date();
        return today <= this.cutOffDay && pastDueRentAmount > upcomingRentAmount / 2;
      }
    },
    rentAmount() {
      if (this.schedule.rentRoll) {
        return this.schedule.rentRoll.upcomingRentAmount;
      }
    },
    pastPaymentDue() {
      if (this.schedule.rentRoll) {
        return this.schedule.rentRoll.pastDueRentAmount;
      }
    },
    upcomingPaymentDue() {
      if (this.schedule.rentRoll) {
        const { pastDueRentAmount, upcomingRentAmount } = this.schedule.rentRoll;

        const totalAmountDue = pastDueRentAmount + upcomingRentAmount;

        return this.applicationAtStartOfMonthHighBalance ? pastDueRentAmount : totalAmountDue;
      }
    },
    support() {
      return {
        phoneNumber: '678-506-0269',
        emailAddress: 'residentservices@hellotill.com',
      };
    },
  },
  methods: {
    async applicationEndpoints(action) {
      const { inProgressApplication, application } = this.$store.state;

      let returnedApplication;

      switch (action) {
        case 'submit':
          returnedApplication = await submitApplication(inProgressApplication, this.$axios);
          break;
        case 'update':
          returnedApplication = await updateApplication(inProgressApplication, this.$axios);
          break;
        case 'approve':
          returnedApplication = await approveApplication(application.applicationId, this.$axios);
          break;
        default:
          break;
      }

      // Put the returned application in the store
      await this.$store.commit('storeApplication', {
        ...returnedApplication,
        hasApplication: true,
      });

      return returnedApplication;
    },
    async logOut() {
      // Clear React Apps auth key.
      localStorage.removeItem('auth._token.local');
      localStorage.removeItem(`auth:${this.$config.tokenEnv}:accessToken`);
      await this.$auth.logout({ withCredentials: true }).catch((err) => {
        this.$log.error({ err });
      });

      // Location.assign instead for router.push in order to reset local state.
      window.location.assign('/v/login');
    },
    contactSupport() {
      window
        .open(
          'https://support.hellotill.com/hc/en-us/requests/new?ticket_form_id=360000792111',
          '_blank'
        )
        .focus();
    },
    openFaq() {
      window
        .open(
          'https://support.hellotill.com/hc/en-us/sections/4412685197709-Frequently-Asked-Questions',
          '_blank'
        )
        .focus();
    },
  },
};
