import _ from 'lodash';

// actions
const actions = {
  async fetchEpmsRecord({ commit }) {
    try {
      if (!this.state.user.userId) throw new Error('could not find user');

      const { data } = await this.$axios.get(`/epms/user/${this.state.user.userId}`);

      commit('storeEpmsRecord', { ...data[0], hasEpmsRecord: !!data[0] });

      return data;
    } catch (err) {
      commit('storeEpmsRecord', { hasEpmsRecord: false });
      this.$log.error('epms record not found');
    }
  },

  async fetchCommunity({ commit }) {
    try {
      const communityId = this.state.application.communityId;

      if (!communityId) throw new Error('could not find community');

      const { data } = await this.$axios.get(`/community/${communityId}`);

      commit('storeCommunity', { ...data, hasCommunity: !!data });

      return data;
    } catch (err) {
      commit('storeCommunity', { hasCommunity: false });
      this.$log.error('community not found');
    }
  },

  async fetchApplication({ commit }) {
    try {
      if (!this.state.user.userId) throw new Error('could not find application');
      const { data } = await this.$axios.get(
        `/api/v2/flex/user/${this.state.user.userId}/application`
      );

      const currentApplications = _.sortBy(
        data
          .map((app) => ({ ...app, sortByDate: new Date(app._createdAt) }))
          .filter(
            (app) =>
              app.status !== 'canceled' && app.status !== 'declined' && app.status !== 'enrolled'
          ),
        'sortByDate'
      ).reverse();

      const [application] = currentApplications;

      commit('storeApplication', { ...application, hasApplication: !!application });

      return application;
    } catch (e) {
      commit('storeApplication', { hasApplication: false });
      commit('storeErrorModal', { errorType: 'application-failed' });
      this.$log.error('application not found');
    }
  },

  async fetchSchedule({ commit }) {
    try {
      if (!this.state.application.applicationId) throw new Error('could not find application');

      const { data: schedule } = await this.$axios.get(
        `/application/${this.state.application.applicationId}/schedule`
      );

      commit('storeSchedule', { ...schedule, hasSchedule: true });

      return schedule;
    } catch (err) {
      commit('storeSchedule', { hasSchedule: false });
      commit('storeErrorModal', { errorType: err.response?.data?.message || 'schedule-failed' });
      this.$log.error('schedule not found');
    }
  },

  async fetchSubscription({ commit }) {
    try {
      if (!this.state.user.userId) throw new Error('could not find application');

      // Optionally pull subscriptions by status ?status=active|closed|collections
      const { data } = await this.$axios.get(`/user/${this.state.user.userId}/subscriptions`);

      const currentSubscriptions = _.sortBy(
        data.map((sub) => ({ ...sub, sortByDate: new Date(sub._createdAt) })),
        'sortByDate'
      ).reverse();

      const [subscription] = currentSubscriptions;

      commit('storeSubscription', { ...subscription, hasSubscription: !!subscription });
      commit('storeUser', {
        ...this.state.user,
        hasActiveSubscription:
          subscription.status &&
          !['closed', 'in-collections'].includes(subscription.status) &&
          !subscription.status.startsWith('closed'),
      });

      return subscription;
    } catch (err) {
      commit('storeSubscription', { hasSubscription: false });
      this.$log.error('subscription not found');
    }
  },

  async fetchCurrentSchedule({ commit }) {
    try {
      if (!this.state.subscription.scheduleId) throw new Error('could not find subscription');

      const { data: schedule } = await this.$axios.get(
        `/v3/schedule/${this.state.subscription.scheduleId}`
      );

      // Leave below commented code for now to make testing easier.
      // const mockStatuses = ['complete', 'pending', ''];

      // const mockSchedule = {
      //   ...schedule,
      //   payments: schedule.payments
      //     .filter((payment) => payment.type !== 'till-fee')
      //     .map((payment, i) => ({ ...payment, status: mockStatuses[i] })),
      // };

      // commit('storeSchedule', { ...mockSchedule, hasSchedule: true });

      commit('storeSchedule', { ...schedule, hasSchedule: true });
      return schedule;
    } catch (err) {
      commit('storeSchedule', { hasSchedule: false });
      commit('storeErrorModal', { errorType: 'schedule-failed' });
      this.$log.error('schedule not found');
    }
  },

  async fetchAccount({ commit }) {
    try {
      const userId = this.state.user.userId;
      if (!userId) throw new Error('could not find user');

      const { data } = await this.$axios.get(`/api/flexpay/account/${userId}`);

      commit('storeAccount', { ...data, hasAccount: !!data });

      return data;
    } catch (err) {
      commit('storeAccount', { hasAccount: false });
      this.$log.error('account not found');
    }
  },

  async fetchPlaidLinkToken({ commit }, payload) {
    try {
      const { data } = await this.$axios.post(`/plaid-link-token`, {
        redirectPath: `${window.location.origin}/v/oauth-verification`,
      });

      commit('storePaymentProfile', { plaidLinkToken: data.token });

      localStorage.setItem('link_token', data.token);
      localStorage.setItem('link_token_redirect', payload.path);
    } catch (err) {
      this.$log.error('plaid link token could not be fetched');
    }
  },
};

export default actions;
