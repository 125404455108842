/* eslint-disable no-console */
/* eslint-disable camelcase */
import * as FullStory from '@fullstory/browser';

export default (ctx, inject) => {
  const { $auth, store } = ctx;

  // Try catch ensures that FullStory is only initiated once
  try {
    FullStory.getCurrentSessionURL();
  } catch (error) {
    FullStory.init({ orgId: '16FZ4V' });
    inject('fullStory', FullStory);
  }

  if ($auth?.$state.loggedIn) {
    const { userId, firstName, lastName, email } = $auth.user;

    FullStory.identify(userId, { displayName: `${firstName} ${lastName}`, email });
  }

  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case 'storeApplication':
        // Wait for the rent roll record to be stored, then save this data to FS
        if (state.application.hasApplication) {
          const { userId, applicationId, applicationType, scheduleData } = state.application;

          // Store the below values for all submitted applications
          FullStory.setUserVars({ userId, applicationId, applicationType });

          // Additional fields captured only for v2 application
          if (scheduleData) {
            const {
              program_eligibility_with_assistance,
              program_eligibility_with_assistance_reason,
              program_eligibility_without_assistance,
              program_eligibility_without_assistance_reason,
            } = state.application.scheduleData;

            FullStory.setUserVars({
              userId,
              applicationId,
              applicationType,
              program_eligibility_with_assistance,
              program_eligibility_with_assistance_reason,
              program_eligibility_without_assistance,
              program_eligibility_without_assistance_reason,
            });
          }
        }
        break;
      default:
        break;
    }
  });
};
