export default async function (ctx) {
  const { store, app, route, $auth, $config, $log } = ctx;
  const currentPath = app.router.currentRoute.fullPath;

  const clearTokens = async () => {
    localStorage.removeItem('auth._token.local');
    localStorage.removeItem(`auth:${$config.tokenEnv}:accessToken`);
    await $auth.logout({ withCredentials: true }).catch((err) => $log.error({ err }));
  };

  if ($auth.$state.loggedIn) {
    // Unlikely this would happen in production but if there's already a logged in user
    // we should clear them out and retry the route in the below cases.
    if (currentPath.includes('/signup/activate')) {
      clearTokens();
      app.router.push(currentPath);
    } else if (currentPath.includes('/link')) {
      clearTokens();
      window.location.assign(`/v${currentPath}`);
    } else if (!store.state.user.userId) {
      // Otherwise put the user in the store and continue.
      store.commit('storeUser', $auth.$state.user);
    }

    // Evaluate eligibility for FR3 apply flow
    if (
      route.path.includes('apply') &&
      !route.path.includes('/apply/community-match') && // We won't have access to the application on this screen so the redirect will happen on the page itself
      !route.path.includes('/apply/confirmation')
    ) {
      // Fetch the subscription so we can properly route the user, even though we don't need it in this logic
      !store.state.subscription.hasSubscription && (await store.dispatch('fetchSubscription'));
      // Fetch the application if it's not already in store
      const application = !store.state.application.hasApplication
        ? await store.dispatch('fetchApplication')
        : store.state.application;

      if (application?.applicationType) {
        const isFR3Eligible = application?.applicationType === 'V3_FLEXIBLE_RENT'; // Only users with a V3_FLEXIBLE_RENT type should remain on the FR3 apply flow
        const isFR3ApplyPath = !route.fullPath.includes('v2'); // Only reroute the user if they're attempting to access the FR3 apply flow but are not eligible to.
        const isExistingRoute = app.router.resolve(`/v2${route.fullPath}`).route.name; // If a page exists in the FR3 apply flow, but not in FR2, the user will end up at the start of the FR2 apply flow

        if (!isFR3Eligible && isFR3ApplyPath) {
          isExistingRoute
            ? app.router.push(`/v2${route.fullPath}`)
            : app.router.push(`/v2/apply/community-match`);
        }
      }
    }
  }

  // Log relevant info in FS
  app.$fullStory.log('Application:', store.state.application);
  app.$fullStory.log('Schedule:', store.state.schedule);

  // Track page view in hubspot
  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['setPath', route.fullPath]);
  _hsq.push(['trackPageView']);
}
