/* eslint-disable no-console */
/**
 * @typedef {object} logger
 * @property {(...any) => void} debug
 * @property {(...any) => void} warn
 * @property {(...any) => void} error
 * @property {(...any) => void} info
 * @property {(...any) => void} todo
 */

/** @type {import("@nuxt/types").Plugin} */
export default (context, inject) => {
  const isDev = context.$config.isDev;
  // prettier-ignore
  /** @type {logger} */
  const log = {
    debug: (...args) => (isDev ? console.debug(`DEBUG:`, ...args) : null),
    warn: (...args) => (isDev ? console.warn(`WARN:`, ...args) : null),
    error: (...args) => (isDev ? console.error(`ERROR:`, ...args) : null),
    info: (...args) => (isDev ? console.info(`INFO:`, ...args) : null),
    todo: (...args) => (isDev ? console.log('%c TODO:', 'font-weight: bold', ...args) : null),
  };
  inject('log', log);
  context.$log = log;
};
