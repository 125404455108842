/* eslint-disable no-console */
import * as LDClient from 'launchdarkly-js-client-sdk';

export default async (context, inject) => {
  const { $config, $auth } = context;

  const clientId = $config.launchDarkly.clientId;
  const user = $auth?.$state.loggedIn
    ? { key: $auth.user.userId, email: $auth.user.email }
    : { anonymous: true };

  await new Promise((resolve) => {
    const ldClient = LDClient.initialize(clientId, user, {
      sendEvents: true,
      privateAttributeNames: ['email'],
    });

    ldClient.on('ready', () => {
      inject('flags', ldClient.allFlags());
      context.$flags = ldClient.allFlags();
      resolve();
    });
  });
};
