import { render, staticRenderFns } from "./basic.vue?vue&type=template&id=7abb8872&"
import script from "./basic.vue?vue&type=script&lang=js&"
export * from "./basic.vue?vue&type=script&lang=js&"
import style0 from "./basic.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavBar: require('/Users/juliaschultz/dev/hello-till/web-app/components/nav/NavBar.vue').default,FullScreenModal: require('/Users/juliaschultz/dev/hello-till/web-app/components/FullScreenModal.vue').default,DevTool: require('/Users/juliaschultz/dev/hello-till/web-app/components/DevTool.vue').default})
