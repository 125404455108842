export default async function (applicationId, axios) {
  try {
    if (!applicationId) {
      throw new Error('Unable to locate application.');
    }

    // Request approves the application
    const approvedApplication = await axios.put(`/application/${applicationId}/approve`, {});

    // Returns the full approved application
    return approvedApplication.data;
  } catch (err) {
    // Return the application ID if theres an error
    throw new Error('Unable to approve application.');
  }
}
