//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default { name: 'AlertIcon' };
