//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import global from '~/mixins/global';

// Components
import ChevronIcon from '~/components/icons/ChevronIcon';

export default {
  name: 'NavMenu',
  components: { ChevronIcon },
  mixins: [global],
  data: () => ({
    menu: [
      { path: '/rent', label: 'Overview' },
      { path: '/rent/payment-history', label: 'Payment history' },
      { path: '/rent/manage-autopay', label: 'Autopay settings' },
      { path: '/rent/payment-method', label: 'Payment methods' },
      { path: '/rent/make-payment', label: 'Make a payment' },
      { path: '/settings', label: 'Account settings ' },
    ],
  }),
  computed: {
    ...mapGetters({ user: 'getUser', subscription: 'getSubscription' }),
  },
  methods: {
    openChat() {
      window.zE.activate();
    },
    handleClick() {
      this.$emit('click');
    },
    async hardLogout() {
      localStorage.removeItem('prefs');
      this.$store.commit('prefs/resetState');
      await this.logOut();
    },
  },
};
