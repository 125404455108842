// READ ME:
// This 'subscriber' middleware is initiated when the app loads and listens for any changes
// to the user, rentRoll, application, schedule and subscription objects in the Vuex store.
// The specific changes listed out below will trigger users being routed to the the best path
// based on their enrollment and application status.
//

export default function (ctx) {
  const { store, app } = ctx;
  // Called after every store mutation.
  store.subscribe((mutation, state) => {
    // Its important that this variable is set inside the subscriber
    const currentPath = app.router.currentRoute.fullPath;
    const currentBasePath = currentPath.split('/')[1];

    switch (mutation.type) {
      case 'storeUser':
        if (state.auth.loggedIn) {
          // Fetch subscription when the user is successfully stored
          !state.subscription.hasSubscription && store.dispatch('fetchSubscription');
        }
        break;
      case 'storeSubscription':
        // If the user has a subscription, fetch the rest of their account information
        if (state.subscription.hasSubscription) {
          !state.account.hasAccount && store.dispatch('fetchAccount');
          // In the following cases route to the dashboard overview screen
          if (
            !currentBasePath.includes('rent') &&
            (state.subscription.status === 'repaying' ||
              state.subscription.status.startsWith('funding') ||
              state.subscription.status.startsWith('closed') ||
              state.subscription.status.startsWith('pending') ||
              state.pageContext.isIframe)
          ) {
            // Route V3 users to the dashboard overview screen, v2 users will be routed via the '/v' path
            if (state.subscription.program === 'V3_FLEXIBLE_RENT') {
              app.router.push('/rent');
            }
            // In the below case users should see the confirmation screen
          } else if (!currentBasePath.includes('rent')) {
            window.location.href = '/rent';
          }
          // Users without a subscription attempting to hit a page with the base path 'rent' will be rerouted from the '/' index page
        } else if (!state.subscription.hasSubscription && currentBasePath.includes('rent')) {
          app.router.push('/');
        }
        // In all other cases we can assume the user is still applying, so fetch the application
        else {
          !state.application.hasApplication && store.dispatch('fetchApplication');
        }
        break;
      case 'storeApplication':
        // If the application is successfully stored...
        if (state.application.status) {
          const { status, autoMatched, applicationType } = state.application;
          // Manually matched pending apps should go to the /apply/pending screen
          if ((!autoMatched && status === 'pending') || status === 'flagged')
            app.router.push('/apply/pending');
          // For V3_FLEXIBLE_RENT applications
          else if (applicationType === 'V3_FLEXIBLE_RENT') {
            if (
              (!currentPath.includes('/apply/community-match') || // If a user is signing up via legacy partner path they'll should still see the community match screen.
                state.pageContext.isIframe) && // Users signing up via the iframe will skip the community match screen in all cases.
              status === 'in_progress' // An in_progress app tells us that the user has not yet passed the eligibility criteria on /apply/rent-info
            )
              app.router.push('/apply/features');
            // Users with the 'approved_agreed_to_schedule'  or approved status need to return to
            // the /set-schedule screen so we can correctly fetch the v3 schedule to be used in subsequent pages
            if (['approved_agreed_to_schedule', 'approved'].some((str) => status.includes(str))) {
              app.router.push('/apply/set-schedule');
            }
          }
          // Fetch the schedule in every other case for BUDGET_AND_SAVE applications
          else if (applicationType === 'BUDGET_AND_SAVE') store.dispatch('fetchSchedule');
          // Fetch the community if its not already in store
          !state.community.hasCommunity && store.dispatch('fetchCommunity');
        }

        // When there is no existing subscription and no "active" application the user as they go through the apply flow
        if (currentPath.includes('apply') || currentPath === '/') {
          const { status, declineReason } = state.application;
          const { hasSubscription } = state.subscription;

          // Cases where we should route the user to the beginning of the flow.
          if (!hasSubscription && (!status || status === 'canceled' || status === 'declined')) {
            // Triggers an error modal when an application is auto declined.
            if (declineReason === 'DOES_NOT_MEET_COMMUNITY_ELIGIBILITY_REQUIREMENTS')
              store.commit('storeErrorModal', { errorType: 'community-eligibility-error' });
            else if (status === 'declined')
              store.commit('storeErrorModal', { errorType: 'application-auto-declined' });
            else app.router.push('/apply/community-match');
          }
        }

        // Do not allow navigation to a page in the middle of the deny flow, start at /apply/community-match
        if (!state.application.status && currentPath.includes('match'))
          app.router.push('/apply/community-match');

        break;
      case 'storeSchedule':
        // If a schedule is stored successfully the user has submitted an app and is in the onboarding flow

        /* eslint-disable  no-case-declarations, */
        const { application: { status, isOnHold, executionArn } } = state; // prettier-ignore

        // Route pending apps away from /set-schedule screen
        if (status === 'pending') {
          // Manually matched app should hit this condition.
          if (!state.schedule.hasSchedule || isOnHold) app.router.push('/apply/pending');
          // Allow pending apps to route directly to the community-match or set-schedule screen
          else if (!['community-match', 'set-schedule'].some((str) => currentPath.includes(str))) {
            app.router.push(`/apply/features`);
          }
        }

        // If the schedule is expired go to the schedule screen
        else if (status === 'schedule_expired') {
          app.router.push('/apply/set-schedule');
        }

        // If payment method is invalid go to add payment screen
        else if (status === 'payment_method_invalid') {
          app.router.push(`/apply/payment-method`);
        }

        // Send approved apps to the accept-schedule page, unless they hit set-schedule
        else if (status === 'approved') {
          // Presence of "executionArn" tells us that the app is in the process of being enrolled.
          if (executionArn) app.router.push('/apply/confirmation');
          // Make sure user can reach the oauth screen
          else if (currentPath.includes('/oauth-verification')) app.router.push(currentPath);
          // Do not re-route approved users as they're navigating within the apply flow
          else if (!currentPath.includes('/apply')) app.router.push(`/apply/set-schedule`);
        }

        // Send everyone else down the path they hit, unless its the basepath, then send them to the community match screen.
        else {
          app.router.push(currentPath === '/' ? `/apply/community-match` : currentPath);
        }
        break;
      default:
        break;
    }
  });
}
