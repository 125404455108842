//
//
//
//
//
//
//
//
//

export default { name: 'MenuIcon' };
